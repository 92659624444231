import React from 'react'
import './Header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';


const Header = () => (

  <div className="ai__header section__padding" id="home">
    <hr className='line' />
    <div className="ai__header-content">
      <h1 className="gradient__text">"Unleashing the power of AI to shape a smarter future."
      </h1>
      <p>The AI Club is a community of individuals passionate about exploring the limitless possibilities of artificial intelligence. Our members are committed to learning, sharing knowledge and skills, and collaborating to create innovative solutions using AI. 
      </p>
      <div className="ai__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Join Us</button>
      </div>

      <div className="ai__header-content__people">
        <img src={people} />
        <p>160+ people has joined our club in last 24 hours</p>
      </div>
    </div>

    <div className="ai__header-image">
      <img className='vert-move' src={ai} />
    </div>
  </div>
)

export default Header