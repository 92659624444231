import React from 'react';
import Article from '../../components/article/Article';
import blog01 from '../../assets/blog01.jpg';
import blog02 from '../../assets/blog02.jpg';
import blog03 from '../../assets/blog03.jpg';
import blog04 from '../../assets/blog04.jpg';
import blog05 from '../../assets/blog05.jpg';

import './Blog.css';

const Blog = () => (
  <div className="ai__blog section__padding" id="blog">
    <div className="ai__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
    </div>
    <div className="ai__blog-container">
      <div className="ai__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2021" text="The ethics of AI: exploring the ethical considerations of developing and using AI, including issues such as bias, privacy, and accountability." />
      </div>
      <div className="ai__blog-container_groupB">
        <Article imgUrl={blog02} date="Mar 26, 2023" text="AI and social impact: discussing how AI can be used to address social challenges, from poverty and inequality to healthcare and education access." />
        <Article imgUrl={blog03} date="Mar 27, 2023" text="AI and creativity: exploring the role of AI in art, music, and other creative fields, from generative art to computer-generated music and storytelling." />
        <Article imgUrl={blog04} date="Mar 28, 2023" text="AI and cybersecurity: analyzing the potential of AI to enhance cybersecurity, from threat detection to data encryption and network security." />
        <Article imgUrl={blog05} date="Mar 29, 2023" text="AI in finance: examining how AI is disrupting the financial industry, from trading algorithms to risk management and fraud detection." />
      </div>
    </div>
  </div>
);

export default Blog;