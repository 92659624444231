import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Process vast amounts of data',
    text: 'AI has the ability to process vast amounts of data, enabling it to recognize patterns, make predictions, and generate insights that can drive innovation and progress across industries.',
  },
  {
    title: 'Solve complex problems easily ',
    text: 'AI can help us solve complex problems that would be impossible to tackle with traditional approaches, such as predicting natural disasters, designing new materials, or developing new treatments for diseases.',
  },
  {
    title: 'Can help in every sector',
    text: 'AI can help us democratize access to education, healthcare, and opportunities, creating a more equitable and inclusive world for all.',
  },
  {
    title: 'More informed decisions',
    text: 'AI can help us make more informed decisions, based on data-driven insights and predictions, reducing the risk of errors, biases, and inefficiencies.',
  },
];

const Features = () => (
  <div className="ai__features section__padding" id="features">
    <div className="ai__features-heading">
      <h1 className="gradient__text">Empowering humanity with the limitless potential of AI.</h1>
      <p>Explore more </p>
    </div>
    <div className="ai__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;