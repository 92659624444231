import React from 'react'
import gpt from '../../assets/gpt.png';
import mid from '../../assets/mid.png';
import fire from '../../assets/fire.png';
import murf from '../../assets/murf.png';
import jas from '../../assets/jas.png';
import './Brand.css';
const Brand = () =>(
  <div>
    <div className="gradient__text__cta"><h3>Top AI tools Known so far</h3></div>
  <div className="ai__brand section__padding">
    <div>
      <img className='img' src={gpt} />
    </div>
    <div>
      <img className='img'src={jas} />
    </div>
    <div>
      <img className='img'src={murf} />
    </div>
    <div>
      <img className='img'src={fire} />
    </div>
    <div>
      <img className='img' src={mid} />
    </div>
  </div>
  </div>
);

export default Brand