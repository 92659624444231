import React from 'react'
import possibilityImage from '../../assets/possibility.png';
import './Possible.css';

const Possible = () => (
  <div className="ai__possibility section__padding" id="possibility">
    <div className="ai__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="ai__possibility-content ">
      <h4>Personalized learning</h4>
      <h1 className="gradient__text"> AI-powered tutoring systems <br />systems</h1>
      <p>Intelligent tutoring: AI-powered tutoring systems can provide students with real-time feedback, guidance, and support, helping them to master complex concepts and skills more quickly and effectively.</p>
      <h4>Stay tuned for updates</h4>
    </div>
  </div>
);
export default Possible