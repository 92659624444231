import React from 'react'
import Features from '../../components/feature/Feature';
import './What.css';

const What = () => (
  <div className="main">
  <div className="ai__whatai section__margin" id="wai">
    <div className="ai__whatai-feature">
      <Features title="What is AI" />
      <p className='whatpara'>
      AI, or artificial intelligence, refers to the development of computer systems that can perform tasks that typically require human intelligence, such as learning, reasoning, problem-solving, perception, and decision-making.       </p>
    </div>
    <div className="ai__whatai-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="ai__whatai-container">
      <Features title="Virtual assistant" text=" Apple's Siri, Amazon's Alexa, These assistants use natural language processing and machine learning algorithms to understand and respond to user  commands." />
      <Features title="Healthcare" text="Analyze medical images and patient data to help doctors make more accurate diagnoses and develop personalized treatment plans." />
      <Features title=" Maintenance" text=" where machine learning algorithms analyze data from sensors on machinery to predict when maintenance is needed, reducing downtime and improving efficiency." />
    </div>
  </div>
  </div>
);

export default What