import React from 'react';
import logo from '../../assets/logo.svg';
import './Footer.css';

const Footer = () => (
  <div>
    <hr/>
  <div className="ai__footer section__padding">
   


    <div className="ai__footer-links">
      <div className="ai__footer-links_logo">
        <img src={logo} alt="ai_logo" />
        <p>It is a platform for like-minded individuals to share their knowledge, ideas, and experiences, and collaborate on AI projects and initiatives.</p>
      </div>
      <div className="ai__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="ai__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="ai__footer-links_div">
        <h4>Get in touch</h4>
        <p>Bhopal(MP)</p>
        <p>999XXX303</p>
        <p></p>
      </div>
    </div>

    <div className="ai__footer-copyright">
      <p>@2023 Vishesh. All rights reserved.</p>
    </div>
  </div>
  </div>
);

export default Footer;