import React from 'react'
import { Brand, Cta, Navbar } from './components';
import { Blog, Footer,Features, Header, Possible, What } from './containers';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className="gradient_bg">
        <Navbar />
        <Header />
      </div>
      <Brand />
      <What />
      <Features />
      <Possible />
      <Cta />
      <Blog />
      <Footer />


    </div>
  )
}

export default App
